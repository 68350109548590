module.exports = {
  siteTitle: "Matt's Portfolio", // <title>
  manifestName: "Matt's Portfolio",
  manifestShortName: 'Matt', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#515355',
  manifestThemeColor: '#1f2024',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-prologue/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Matthew Wood',
  heading: 'Full Stack Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/ecexplorer',
    },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/mattbwood/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/millennialtrvlr',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:matt@mattwood.dev',
    },
  ],
};
